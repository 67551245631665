.datePickerWrap {
  max-width: unset;
}

.detailsField {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.descriptionField {
  padding-top: 8px;
}