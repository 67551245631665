@import 'theme/variables.scss';

.root {
  margin-bottom: 15px;

  &.hasError {
    :global {
      input:not(.ant-popover-inner-content input),
      .editor-wrap,
      .react-select__control,
      .ant-checkbox-inner:not(.ant-popover-inner-content .ant-checkbox-inner),
      div[data-error],
      textarea {
        border-color: $color-red !important;
        box-shadow: unset;
      }

      input:hover:not(.ant-popover-inner-content input),
      .editor-wrap:hover,
      .react-select__control:hover,
      .ant-checkbox-inner:hover:not(
          .ant-popover-inner-content .ant-checkbox-inner
        ),
      textarea:hover {
        border-color: $color-red !important;
        box-shadow: 0 0 0 2px $color-red-90 !important;
      }
    }
  }

  .label {
    position: relative;
    font-size: 12px;
    line-height: 16px;
    color: $color-black-55;
    margin-bottom: 4px;
    display: block;
  }
}
